.summary-label,
.summary-value {
  font-size: 0.8rem;
  color: #525f7f;
  line-height: 1;
}

.summary-value {
  font-weight: 600;
}
