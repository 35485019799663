.translate {
  height: 100%;

  &:after {
    content: '';
    left: 0;
    position: absolute;
    top: 65%;
    z-index: 1;
    opacity: 0.6;
    border-top: 35vh solid transparent;
    border-left: 50vw solid #11cdef;
    border-right: 50vw solid #2dce89;
    border-bottom: 0px solid transparent;
  }

  .card {
    background: #fff;
    box-shadow: 0 15px 35px RGB(50 50 93 / 10%), 0 5px 15px RGB(0 0 0 / 7%);
    border: 0;
    //height: 65vh;
    height: 100%;
    padding-bottom: 5%;
    z-index: 2;
  }

  .row {
    &.top-details-panel {
      height: 25%;
    }

    &.bottom-details-panel {
      height: 50vh;
      overflow-y: auto;
    }
  }

  hr.line-primary {
    margin-top: 0px;
    background-color: #5e72e4;
  }

  h4.header-label {
    text-align: left;
    margin-top: 0.8rem;
  }
  h6.header-label {
    margin-top: 0.8rem !important;
  }
  .btn-lg {
    width: 120px;
  }

  .panel-tab {
    hr.line-primary {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      background-color: #5e72e4;
      width: 70%;
      font-weight: 600;
    }

    .header-label {
      &.active {
        color: #5e72e4 !important;
        border-color: transparent !important;
        background-color: transparent;
        font-weight: 600;
      }
    }

    .summary-container {
      display: block;
      padding: 0.25rem 0.75rem;
    }
  }

  .summary-total {
    width: 70%;
    height: 92px;
    margin: 0 auto;
    background: linear-gradient(35deg, #e4405f, #ea6d84) !important;
    color: #fff;

    .total {
      font-size: 2rem;
      font-weight: bolder;
      margin-bottom: 0;
      margin-top: 10px;
      padding-top: 10px;
    }

    .sub-total {
      font-size: 12px;
      margin-top: -7px;
      font-weight: 300;
      line-height: 1.7;
      padding-bottom: 15px;
    }
  }

  .tab-content {
    height: 90%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .svg-inline--fa.fa-flag-checkered {
    height: 4rem !important;
  }
}
