.contact-order {
  .form-group {
    &.invalid {
      color: #fb6340;
    }

    &.text:hover {
      cursor: pointer;
      color: #11cdef;
    }

    &.text-start {
      text-align: start;
    }
  }
}
