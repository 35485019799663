.card-container {
  padding-top: 115px;
  text-align: center;

  .title {
    line-height: 1.1;
    margin-bottom: 30px;
  }

  .container .description {
    margin-bottom: 20px;
  }

  .info {
    padding: 30px 10px;
    margin: 30px auto 0;
    opacity: 0.8;
    // width: 250px;
    width: 140%;
    margin-left: -40%;
    position: relative;

    :hover {
      opacity: 1;
      z-index: 100;
      cursor: pointer;
    }

    &.info-raised {
      margin-top: -5px;
    }
  }

  .info .icon {
    margin-left: 15px;
  }

  .info .info-title {
    font-weight: 700;
    padding: 0 15px;
  }

  .info .description {
    color: #fff;
    padding: 0 15px;
  }

  .details {
    padding: 30px 10px;
    margin: 30px auto 30px;
    width: 100%;
    position: relative;
  }

  .details-title {
    font-weight: 700;
    padding: 0 15px;
  }

  .details-description {
    padding: 0 15px;
  }
}
