.header-locale {
  .dropdown-menu {
    min-width: 5rem;
    text-align: center;
  }

  .btn-secondary {
    background: transparent;
  }
}

.navbar-collapse {
  &.show {
    .header-locale {
      padding-bottom: 0.625rem;
    }
  }
}
