.details-body {
  margin: 0;
  font-family: sans-serif;
}

.hide-wrapper {
  display: none !important;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.details-animation {
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.footer-container {
  border-top: 1px solid red;
  /* background: lightgray; */
}
