.nav-item:hover {
  cursor: pointer;
}

.nav-item.show-selected,
.nav-item .nav-link:hover {
  color: #f5365c !important;
}

.hide {
  display: block;
}
