.paymentCard {
  &.card {
    height: 30vh !important;
    margin-bottom: 10%;
    z-index: 0 !important;
    display: flex;

    /*&:hover {
      background: #ddd !important;
      opacity: 0.9;
    }*/

    .option-container {
      height: 100px;
    }

    &.card-disabled {
      background: #eee !important;
      opacity: 0.6;
    }

    .info-title {
      margin: 0 0 0 0 !important;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &.small-card {
    height: 10vh !important;
  }
}
