.basic {
  max-width: 900px !important;
  height: 90%;

  .modal-content {
    height: 100%;

    .modal-body {
      height: 70%;
    }
  }
}

@media (max-width: 993px) {
  .basic {
    max-width: 700px !important;
  }
}

@media (max-width: 770px) {
  .basic {
    max-width: 550px !important;
  }
}
