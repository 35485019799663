.card-description {
  font-size: 0.875rem;
}

.card-header {
  border-bottom: none;
}

.card-profile {
  min-width: 250px;
  border: none !important;
}

.card-image img {
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.card-description-details {
  font-size: 0.8125rem;
}

.card-description-details.label {
  white-space: nowrap;
}

.card-description-details.value {
  margin-left: auto;
  margin-right: 0;
}

.row-spacer {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  vertical-align: top;
  border-top: 0.0625rem solid #dee2e6;
}

.col-spacer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
